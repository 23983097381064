import request from '@/utils/request'

export function getAddress(data) {
  return request({
    url: '/api/index/address',
    method: 'post',
    data
  })
}

export function addAddress(data) {
  return request({
    url: '/api/index/add_address',
    method: 'post',
    data
  })
}
//api/index/address_default
export function setDefaultAddress(data) {
  return request({
    url: '/api/index/address_default',
    method: 'post',
    data
  })
}

export function updateAddress(data) {
  return request({
    url: '/api/index/edit_address',
    method: 'post',
    data
  })
}

export function deleteAddress(data) {
  return request({
    url: `/api/index/address_del`,
    method: 'post',
    data
  })
}
