import request from '@/utils/request'

export function getInfo() {
  return request({
    url: '/api/index/get_user',
    method: 'post'
  })
}
//发送验证码
export function send(data) {
  return request({
    url: '/api/sms/send',
    method: 'post',
    data
  })
}
//send
//用户协议 隐私政策

export function getArticle(data) {
  return request({
    url: '/api/index/article',
    method: 'post',
    data
  })
}
export function profile(data) {
  return request({
    url: '/api/user/profile',
    method: 'post',
    data
  })
}

export function login(data) {
  return request({
    url: '/api/user/mobilelogin',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}

export function setRegistry(data) {
  return request({
    url: '/api/registry',
    method: 'post',
    data
  })
}

export function updatePassword(data) {
  return request({
    url: '/user/updatePassword',
    method: 'post',
    data
  })
}

export function uploadAvatar(data) {
  return request.formDataPost('/user/uploadAvatar', data)
}

export function getMailCode(data) {
  return request({
    url: '/user/sendEmailCode',
    method: 'post',
    data,
    timeout: 20000
  })
}
//修改昵称 api/index/profile
export function updateprofile(data) {
  return request({
    url: '/api/user/profile',
    method: 'post',
    data
  })
}
//我的下级 /index/children

export function getChildren(data) {
  return request({
    url: '/api/index/children',
    method: 'post',
    data
  })
}
//修改手机号 https://yushi.junhecms.com/index/changemobile


export function getChangemobile(data) {
  return request({
    url: '/api/user/changemobile',
    method: 'post',
    data
  })
}

//绑定微信 https://yushi.junhecms.com/index/bind_wechat

export function bind_wechat(data) {
  return request({
    url: '/api/index/bind_wechat',
    method: 'post',
    data
  })
}
export function getAddress(data) {
  return request({
    url: '/api/index/address',
    method: 'post',
    data
  })
}

export function addAddress(data) {
  return request({
    url: '/api/index/add_address',
    method: 'post',
    data
  })
}
//index/address_default
export function setDefaultAddress(data) {
  return request({
    url: '/api/index/address_default',
    method: 'post',
    data
  })
}

export function updateAddress(data) {
  return request({
    url: '/api/index/edit_address',
    method: 'post',
    data
  })
}

export function deleteAddress(data) {
  return request({
    url: `/api/index/address_del`,
    method: 'post',
    data
  })
}


//提现 https://yushi.junhecms.com/index/withdraw
export function withdraw(data) {
  return request({
    url: '/api/index/withdraw',
    method: 'post',
    data
  })
}
//提现列表  https://yushi.junhecms.com/index/withdraw_list

export function withdrawlist(data) {
  return request({
    url: '/api/index/withdraw_list',
    method: 'post',
    data
  })
}
//收支记录 api/index/money_log

export function moneyLog(data) {
  return request({
    url: '/api/index/money_log',
    method: 'post',
    data
  })
}

export function getGoodslist(data) {
  return request({
    url: '/api/index/goods_list',
    method: 'post',
    data
  })
}


export function gettrustlist(data) {
  return request({
    url: '/api/index/trust_list',
    method: 'post',
    data
  })
}
export function getTrust(data) {
  return request({
    url: '/api/index/trust',
    method: 'post',
    data
  })
}

export function cancel_trust(data) {
  return request({
    url: '/api/index/cancel_trust',
    method: 'post',
    data
  })
}
//取消委托 https://yushi.junhecms.com/index/cancel_trust
//委托 /index/trust
//index/trust_list

//https://yushi.junhecms.com/index/add_order

export function addorder(data) {
  return request({
    url: '/api/index/add_order',
    method: 'post',
    data
  })
}

export function getHomeData(data) {
  return request({
    url: '/api/index/index',
    method: 'post',
    data
  })
}

//删除取消订单 https://yushi.junhecms.com/index/cancel_order
export function orderDelete(data) {
  return request({
    url: '/api/index/cancel_order',
    method: 'post',
    data
  })
}


//订单列表 /index/order_list
export function getorderList(data) {
  return request({
    url: '/api/index/order_list',
    method: 'post',
    data
  })
}

export function orderPrepay(data) {
  return request({
    url: '/api/index/pay',
    method: 'post',
    data
  })
}

//我的仓库 api/index/store
export function getStore(data) {
  return request({
    url: '/api/index/store',
    method: 'post',
    data
  })
}
//回收 api/index/recover

export function getStorerecover(data) {
  return request({
    url: '/api/index/recover',
    method: 'post',
    data
  })
}
//兑换 api/index/change
export function getStorechange(data) {
  return request({
    url: '/api/index/change',
    method: 'post',
    data
  })
}
//上传图片 https://yushi.junhecms.com/common/upload

export function upload(data) {
  return request({
    url: '/api/common/upload',
    method: 'post',
    data
  })
}

//会员权益 https://yushi.junhecms.com/index/level

export function getlevel(data) {
  return request({
    url: '/api/index/level',
    method: 'post',
    data
  })
}


//委托记录1 https://yushi.junhecms.com/index/record

export function weiturecord(data) {
  return request({
    url: '/api/index/record',
    method: 'post',
    data
  })
}
//https://yushi.junhecms.com/index/store_trust

export function store_trust(data) {
  return request({
    url: '/api/index/store_trust',
    method: 'post',
    data
  })
}
// /https://yushi.junhecms.com/index/config

export function config(data) {
  return request({
    url: '/api/index/config',
    method: 'post',
    data
  })
}