import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '*',
    name: 'Home',
    // 路由懒加载：https://router.vuejs.org/zh/guide/advanced/lazy-loading.html
    // webpackPreload：https://www.jianshu.com/p/bbdcfeee7fbc
    component: () => import(/* webpackPreload: true */ '@/views/home'),
    meta: {
      title: '首页',
      showTab: true,
      // keepAlive: true
    }
  },
  // 登录
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login'),
    meta: {
      title: '登录'
    }
  },
  // 注册
  {
    path: '/registry',
    name: 'Registry',
    component: () => import('@/views/auth/registry'),
    meta: {
      title: '注册'
    }
  },
  // 分类
  {
    path: '/category',
    name: 'Category',
    component: () => import('@/views/category'),
    meta: {
      title: '分类',
      showTab: true,
      keepAlive: true
    }
  },
  // 购物车
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('@/views/cart'),
    meta: {
      title: '购物车',
      showTab: true
    }
  },
  // 我的
  {
    path: '/user',
    name: 'User',
    component: () => import('@/views/user'),
    meta: {
      title: '我的',
      showTab: true
    }
  },
  // 用户设置
  {
    path: '/userSetting',
    name: 'UserSetting',
    component: () => import('@/views/userSetting'),
    meta: {
      title: '用户设置',
      showTab: true
    }
  },
    // 隐私政策
    {
      path: '/Privacy',
      name: 'Privacy',
      component: () => import('@/views/Privacy'),
      meta: {
        title: '隐私政策',
        showTab: false
      }
    },
  // 用户设置-昵称
  {
    path: '/userSetting/nickname',
    name: 'UserSetNickname',
    component: () => import('@/views/userSetting/nickname'),
    meta: {
      title: '设置昵称',
      showTab: true
    }
  },
  // 用户设置-生日
  {
    path: '/userSetting/birthday',
    name: 'UserSetBirthday',
    component: () => import('@/views/userSetting/birthday'),
    meta: {
      title: '设置生日',
      showTab: true
    }
  },
  // 用户设置-性别
  {
    path: '/userSetting/gender',
    name: 'UserSetGender',
    component: () => import('@/views/userSetting/gender'),
    meta: {
      title: '设置性别',
      showTab: true
    }
  },
  // 用户设置-邮箱
  {
    path: '/userSetting/email',
    name: 'UserSetEmail',
    component: () => import('@/views/userSetting/email'),
    meta: {
      title: '设置邮箱',
      showTab: true
    }
  },
  // 用户设置-手机
  {
    path: '/userSetting/mobile',
    name: 'UserSetMobile',
    component: () => import('@/views/userSetting/mobile'),
    meta: {
      title: '设置手机号',
      showTab: true
    }
  },
  // 用户设置-密码
  {
    path: '/userSetting/password',
    name: 'UserSetPassword',
    component: () => import('@/views/userSetting/password'),
    meta: {
      title: '设置密码',
      showTab: true
    }
  },
  //用户协议
  {
    path: '/xieyi',
    name: 'xieyi',
    component: () => import('@/views/xieyi'),
    meta: {
      title: '用户协议',
      showTab: false
    }
  },
  {
    path: '/viewUserInfo',
    name: 'viewUserInfo',
    component: () => import('@/views/viewUserInfo'),
    meta: {
      title: '个人信息',
      showTab: false
    }
  },
  //editPhone
  {
    path: '/editPhone',
    name: 'editPhone',
    component: () => import('@/views/editPhone'),
    meta: {
      title: '修改手机号码',
      showTab: false
    }
  },
  //editname
  {
    path: '/editname',
    name: 'editname',
    component: () => import('@/views/editname'),
    meta: {
      title: '修改昵称',
      showTab: false
    }
  },
  {
    path: '/coin',
    name: 'coin',
    component: () => import('@/views/coin'),
    meta: {
      title: '我的余额',
      showTab: false
    }
  },
  {
    path: '/cash',
    name: 'cash',
    component: () => import('@/views/cash'),
    meta: {
      title: '立即提现',
      showTab: false
    }
  },
  //可抢商品
  {
    path: '/keqiangGood',
    name: 'keqiangGood',
    component: () => import('@/views/keqiangGood'),
    meta: {
      title: '可抢商品',
      showTab: false
    }
  },
  {
    path: '/kexuanGood',
    name: 'kexuanGood',
    component: () => import('@/views/kexuanGood'),
    meta: {
      title: '委托代售商品列表',
      showTab: false
    }
  },
  //subordinate
  {
    path: '/subordinate',
    name: 'subordinate',
    component: () => import('@/views/subordinate'),
    meta: {
      title: '我的下级',
      showTab: false
    }
  },
  {
    path: '/poster',
    name: 'poster',
    component: () => import('@/views/poster'),
    meta: {
      title: '我的邀请海报',
      showTab: false
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/vip'),
    meta: {
      title: 'vip',
      showTab: false
    }
  },
  // /poster
  // /kexuanGood
  //
  // 订单列表
  {
    path: '/user/order/list/:active',
    name: 'OrderList',
    props: true,
    component: () => import('@/views/order/list'),
    meta: {
      title: '订单列表',
      showTab: false
    }
  },
  // 商品列表
  {
    path: '/product/:categoryLevel/:cateId',
    name: 'Product',
    props: true,
    component: () => import('@/views/product'),
    meta: {
      title: '商品列表',
      keepAlive: true
    }
  },
  // 金刚位跳转商品列表
  {
    path: '/diamondGoodsList/:diamondId',
    name: 'Diamand',
    props: true,
    component: () => import('@/views/diamondGoodsList'),
    meta: {
      title: '商品列表',
      keepAlive: true
    }
  },
  // 商品详情
  {
    path: '/detail/:goodsId',
    name: 'Detail',
    props: true,
    component: () => import('@/views/detail'),
    meta: {
      title: '商品详情',
      keepAlive: true
    }
  },
  // 商品评论
  {
    path: '/detail/comment/:goodsId/:tagType',
    name: 'Comment',
    props: true,
    component: () => import('@/views/detail/comment'),
    meta: {
      title: '商品评论'
    }
  },
  // 地址管理
  {
    path: '/address',
    name: 'Address',
    component: () => import('@/views/address/list'),
    meta: {
      title: '地址管理'
    }
  },
  // 地址编辑
  {
    path: '/address/edit',
    name: 'AddressEdit',
    component: () => import('@/views/address/edit'),
    meta: {
      title: '地址编辑'
    }
  },
  // 搜索
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/warehouse',
    name: 'warehouse',
    component: () => import('@/views/warehouse'),
    meta: {
      title: '我的仓库'
    }
  },
  // 搜索结果
  {
    path: '/search/list',
    name: 'SearchList',
    component: () => import('@/views/search/list'),
    meta: {
      title: '搜索结果'
    }
  },
  // 确认订单
  {
    path: '/order/confirm',
    name: 'OrderConfirm',
    component: () => import('@/views/order/confirm'),
    meta: {
      title: '确认订单',
      // keepAlive: true
    }
  },
  //兑换changeGood
  {
    path: '/changeGood',
    name: 'changeGood',
    component: () => import('@/views/changeGood'),
    meta: {
      title: '兑换',
      // keepAlive: true
    }
  },
  //productRecord
  {
    path: '/productRecord',
    name: 'productRecord',
    component: () => import('@/views/productRecord'),
    meta: {
      title: '售卖记录',
      // keepAlive: true
    }
  },
  {
    path: '/recycling',
    name: 'recycling',
    component: () => import('@/views/recycling'),
    meta: {
      title: '商品回收',
      // keepAlive: true
    }
  },
  // /recycling
  // 订单支付
  {
    path: '/order/pay',
    name: 'OrderPay',
    component: () => import('@/views/order/pay'),
    meta: {
      title: '订单支付'
    }
  },
  //queren委托 isCondirmweituo
  {
    path: '/isCondirmweituo',
    name: 'isCondirmweituo',
    component: () => import('@/views/isCondirmweituo'),
    meta: {
      title: '确认委托'
    }
  },
  {
    path: '/order/paySuccess',
    name: 'OrderpaySuccess',
    component: () => import('@/views/order/paySuccess'),
    meta: {
      title: '支付成功'
    }
  },
  //确认订单 comfirmOrder
  
  // 订单详情
  {
    path: '/order/detail',
    name: 'OrderDetail',
    // props: true,
    component: () => import('@/views/order/detail'),
    meta: {
      title: '订单详情'
    }
  },
  // 支付状态
  {
    path: '/order/payStatus',
    name: 'PayStatus',
    props: true,
    component: () => import('@/views/order/payStatus')
  },
  // 评论页面
  {
    path: '/goodsComment/:orderGoodsId/:goodsId',
    name: 'GoodsComment',
    props: true,
    component: () => import('@/views/goodsComment'),
    meta: {
      title: '商品评论'
    }
  }
]

// 在VueRouter上配置路由跳转，在router中的index.js中加上以下代码，注意加在use之前
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};

const router = new VueRouter({
  // 记录上个页面的滚动位置
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
