import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Toast, Dialog } from 'vant'

const state = {
  token: getToken(),
  id: '',
  name: '',
  userInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
    state.id = info.id
    state.name = info.nickname
  }
}

const actions = {
  // 登录
  login({ commit }, userInfo) {
    const { mobile, captcha, pid } = userInfo

    return new Promise((resolve, reject) => {
      login({ mobile, captcha, pid })
        .then(res => {
          if(res.code==1){
            commit('SET_TOKEN', res.data.userinfo.token)
            setToken(res.data.userinfo.token)
            resolve()
          }else{
            Toast(res.msg)
            reject(res)
          }

        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 登出
  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          commit('SET_TOKEN', '')
          removeToken()

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // 获取基本用户信息
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(res => {
          // const data = res.data
          if (!res) {
            reject(new Error('获取基本信息失败，请重新登录'))
          }
          commit('SET_USER_INFO', res.data)
          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 重置token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
