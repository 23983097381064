<template>
  <div>
    <div class="tabbar">
      <van-tabbar v-model="active" :active-color="variables.theme" :fixed="false" route>
        <van-tabbar-item :to="{ name: 'Home' }">首页
          <template #icon="props">
            <img :src="props.active ? icon.active : icon.inactive"  style="width:26px;height: 26px"/>
          </template>
        </van-tabbar-item>
        <!-- <van-tabbar-item :to="{name: 'Category'}" icon="bars">分类</van-tabbar-item>
        <van-tabbar-item :to="{name: 'Cart'}" icon="shopping-cart" :badge="totalCartNum">购物车</van-tabbar-item> -->
        <van-tabbar-item :to="{ name: 'User' }">我的
          <template #icon="props">
            <img :src="props.active ? icon.active1 : icon.inactive1"  style="width:26px;height: 26px"/>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
    <div class="tabbar--placeholder" style="width:100%;height:50px" />
  </div>
</template>

<script>
import variables from '@/styles/variables.scss'
import { getCartGoodsCount } from '@/api/cart'

export default {
  data() {
    return {
      active: 0,
      count: 0,
      icon: {
        active: '../../../img/yusihome.png',
        inactive: '../../../img/yushiHomenoSel.png',
        active1: '../../../img/yushiMysel.png',
        inactive1: '../../../img/yushimy.png',
      },
    }
  },
  computed: {
    variables() {
      return variables
    },
    totalCartNum() {
      if (this.count === 0) {
        return ''
      } else {
        return this.count <= 99 ? this.count : 99
      }
    }
  },
  mounted() {
    // this.getCartGoodsCount()
  },
  methods: {
    getCartGoodsCount() {
      getCartGoodsCount().then(res => {
        const { data } = res
        this.count = data
      }).catch(e => { })
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  border-top: 2px solid #f5f5f5;
}
</style>
